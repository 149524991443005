<template>
  <div class="details-page" v-if="allSystems.length !== 0">
    <div class="container">
      <div class="breadcrumbs unprint">
        <router-link :to="`/${this.$i18n.locale}/result`" class="link__back">
          <img src="@/assets/img/icons/breadcrumbs--back.svg" alt="<" class="icon" />
          {{ $t('message.details.breadcrumb.total') }}
        </router-link>
        <router-link :to="`/${this.$i18n.locale}/details/${sectorIndex}`" class="link__back">
          <img src="@/assets/img/icons/breadcrumbs--back.svg" alt="<" class="icon" />
          {{ $t('message.details.breadcrumb.computation') }} {{ sectorFromState.name }}
        </router-link>
      </div>
      <div class="title-with-btn">
        <div class="major-title single-system__page-title unprint" v-if="!currentJunction.isCustom">
          {{ originalJunctionName }}
        </div>
        <Change-name :name="currentJunction.customJunctionName" placement="junction" v-else />
        <div class="buttons">
          <SimpleButton
            color="default"
            font="minor"
            additionalClass="cog"
            @clickedFromSimpleBtn="editJunction"
          >
            <template #icon>
              <img src="@/assets/img/icons/cog--red.svg" alt="" class="icon" />
            </template>
            {{ $t('message.details.junction.edit') }}
          </SimpleButton>
          <delete-btn @btnClicked="deleteJunctionAction" />
        </div>
      </div>

      <div class="details__head-pane common-pane">
        <div class="title">
          {{ $t('message.details.junction.head.title') }}
        </div>
        <div class="content">
          <div class="content__item">
            <div class="content__item-title">
              {{ $t('message.details.junction.head.junction') }}
            </div>
            <div class="content__item-text">
              <img
                src="@/assets/img/icons/details--system--tn.svg"
                alt="TN"
                class="icon"
                v-if="!currentJunction.isCustom"
              />
              {{ junctionName }}
            </div>
          </div>
          <div class="content__item">
            <div class="content__item-title">
              {{ $t('message.details.junction.head.type.title') }}
            </div>
            <div
              class="content__item-text"
              v-text="
                currentJunction.type === 'linear'
                  ? `${$t('message.details.junction.head.type.linear')}`
                  : `${$t('message.details.junction.head.type.chiseled')}`
              "
            ></div>
          </div>
          <div class="content__item">
            <div
              class="content__item-title"
              v-text="
                currentJunction.type === 'linear'
                  ? `${$t('message.details.junction.head.length')}`
                  : `${$t('message.details.junction.head.count')}`
              "
            ></div>
            <div
              class="content__item-text"
              v-text="
                currentJunction.type === 'linear'
                  ? `${currentJunction.count} ${$t('message.units.mp')}`
                  : `${currentJunction.count} ${$t('message.units.pc')}`
              "
            ></div>
          </div>
        </div>
      </div>
      <Details-junction-structure
        :layers="currentJunction.layers"
        :img="imageToDetails"
        type="junction"
      />
      <Details-used-materials-total
        placement="junction"
        :used-materials="currentJunctionUsedMaterials"
      />
      <Details-table-layout placement="junction" v-if="!isHideTableLayout" :subject="{ 'junction': currentJunctionsResult }" :index="sectorIndex">
        <template v-slot:junction>
          <details-simple-table
              :computation="[getJunction]"
              :additional="false"
          ></details-simple-table>
        </template>
      </Details-table-layout>
      <div class="details-title unprint">
        {{ $t('message.details.junction.other') }}
      </div>
      <Junctions-nav-list :junctions="sectorFromState.junctions" placement="details" />
      <ResultSectorsNav />
      <Addons placement="junction" />
    </div>
    <Prefooter @savePdf="savePdf" @saveXls="saveXls" />
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
    <delete-confirmation
      :is-modal-open="isDeleteConfirmationShow"
      :bodyText="'message.deleteJunctionConfirmation.text'"
      :confirmText="'message.deleteSectorConfirmation.confirm'"
      :cancelText="'message.deleteSectorConfirmation.cancel'"
      @close="closeDeleteConfirmation"
      @confirm="deleteJunction"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ChangeName from '@/components/smart/ChangeName'
import Addons from '@/components/dump/Addons'
import Prefooter from '@/components/dump/Prefooter'
import DetailsJunctionStructure from '@/components/smart/Details/DetailsLayersStructure'
import JunctionsNavList from '@/components/elements/Junction/JunctionsNavList'
import ResultSectorsNav from '@/components/smart/ResultSectorsNav'
import DetailsTableLayout from '@/components/smart/Details/DetailsTableLayout'
import DetailsUsedMaterialsTotal from '@/components/smart/Details/DetailsUsedMaterialsTotal'
import DeleteBtn from '@/components/elements/Junction/deleteBtn'
import SimpleButton from '@/components/elements/Dom/Simple-button'
import DeleteConfirmation from '@/components/elements/Modals/DeleteConfirmation'
import { pages } from '@/utils'
import detailsSimpleTable from '@/components/smart/Details/DetailsSimpleTable'

export default {
  data: () => ({
    isDeleteConfirmationShow: false
  }),
  components: {
    DeleteConfirmation,
    SimpleButton,
    DeleteBtn,
    ChangeName,
    Addons,
    Prefooter,
    DetailsJunctionStructure,
    JunctionsNavList,
    ResultSectorsNav,
    DetailsTableLayout,
    DetailsUsedMaterialsTotal,
    detailsSimpleTable
    // DetailsUsedMaterials
  },
  methods: {
    ...mapActions({
      getPdf: 'getPdf',
      getXls: 'getXls',
      deleteJunctionFromDetailsPage: 'deleteJunctionFromDetailsPage',
      markCalculationAsSaved: 'markCalculationAsSaved',
      resetUserInfoResults: 'resetUserInfoResults'
    }),
    editJunction() {
      if (this.isSavedCalculation) {
        this.resetSaveCalculationResult()
      }
      this.$router.push(
        `/${this.$i18n.locale}/system/${this.sectorIndex}/junction/${this.junctionIndex}`
      )
    },
    deleteJunctionAction() {
      this.isDeleteConfirmationShow = true
    },
    deleteJunction() {
      if (this.isSavedCalculation) {
        this.resetSaveCalculationResult()
      }
      this.deleteJunctionFromDetailsPage({
        sectorIndex: this.sectorIndex,
        junctionIndex: this.junctionIndex,
        lang: this.$i18n.locale
      })
    },
    closeDeleteConfirmation() {
      this.isDeleteConfirmationShow = false
    },
    savePdf() {
      this.getPdf({
        lang: this.$i18n.locale,
        type: 'junction',
        sectorIndex: this.sectorIndex,
        junctionIndex: this.junctionIndex,
        fileName: `${this.$t('message.details.junction.save.fileName')} (${this.originalJunctionName})`,
        domenName: `${location.origin}/${this.$i18n.locale}`,
        pageTitle: this.$t('message.api.getPdf.firstPageTitleJunction'),
        header: {
          name: this.originalJunctionName,
          type: this.currentJunction.type,
          count: this.currentJunction.count
        },
        page: pages.details
      })
    },
    saveXls() {
      this.getXls({
        lang: this.$i18n.locale,
        type: 'junction',
        fileName: `${this.$t('message.details.junction.save.fileName')} (${this.originalJunctionName})`,
        sectorIndex: this.sectorIndex,
        junctionIndex: this.junctionIndex,
        title: this.originalJunctionName,
        page: pages.details
      })
    },
    resetSaveCalculationResult() {
      this.resetUserInfoResults()
      this.markCalculationAsSaved(null)
    }
  },
  mounted() {
    if (this.allSystems.length === 0) {
      this.$router.push(`/${this.$i18n.locale}/`)
    }
  },
  computed: {
    ...mapState({
      resultSectors: state => state.result.sectors,
      isLoading: state => state.pages.details.isLoading,
      resultTotal: state => state.result.total,
      isSavedCalculation: state => state.user.objectId
    }),
    ...mapGetters({
      allSystems: 'allPublishedSystems',
      allJunctions: 'allPublishedJunctions'
    }),
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    currentResult() {
      return this.resultSectors[this.sectorIndex]
    },
    currentJunctionsResult() {
      return this.currentResult.junctions[this.junctionIndex]
    },
    currentJunctionUsedMaterials() {
      return Object.values(this.currentResult.junctionsMaterials[this.junctionIndex]).flat()
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    isSingleJunction() {
      return !Object.prototype.hasOwnProperty.call(this.currentJunction, 'groupId')
    },
    currentJunctions() {
      return this.sectorFromState.junctions
    },
    currentJunction() {
      return this.currentJunctions[this.junctionIndex]
    },
    originalJunction() {
      return this.isSingleJunction ? this.originalSingleJunction : this.originalGroupJunction
    },
    hasVariables() {
      return this.currentJunction.variables
    },
    nameWithVariables() {
      const name = this.allJunctions.find(p => p.id === this.currentJunction.id).name
      const variables = Object.entries(this.currentJunction?.variables).map(([key, value]) => `${key} = ${value}`).join(', ')

      return `${name} ${variables}`
    },
    originalJunctionName() {
      if (this.currentJunction.isCustom) {
        return this.currentJunction.customJunctionName
      } else {
        return this.isSingleJunction
          ? this.originalSingleJunctionName
          : this.originalGroupJunctionName
      }
    },
    originalSingleJunction() {
      return this.allJunctions.find(p => p.id === this.currentJunction.id)
    },
    originalSingleJunctionName() {
      return this.hasVariables
        ? this.nameWithVariables
        : this.allJunctions.find(p => p.id === this.currentJunction.id)?.name
    },
    originalGroupJunction() {
      const group = this.allJunctions.find(p => p.id === this.currentJunction.groupId).items
      return group.find(p => p.id === this.currentJunction.id)
    },
    originalGroupJunctionName() {
      const group = this.allJunctions.find(p => p.id === this.currentJunction.groupId)
      const groupJunction = group.items.find(j => j.id === this.currentJunction.id)
      return groupJunction.title
    },
    junctionName() {
      if (this.currentJunction.isCustom) {
        return this.currentJunction.customJunctionName
      }
      return this.currentJunction.variables ? this.originalSingleJunctionName : this.originalJunction.name
    },
    imageToDetails() {
      if (!this.currentJunction.isCustom) {
        return this.originalJunction.img_original
      } else {
        if (this.currentJunction.isCustom && this.currentJunction.drawingUrl === null) {
          return 'default'
        } else {
          return this.currentJunction.drawingUrl
        }
      }
    },
    isHideTableLayout() {
      return this.currentJunctionsResult.every(item => !item.materials.length)
    },
    getJunction() {
      return this.resultTotal.materials[this.sectorIndex].junction[this.junctionIndex]
    }
  }
}
</script>

<style scoped lang="sass">
.details-page
  .title-with-btn
    @extend %title-with-multiple-btns
  .breadcrumbs
    display: flex
    flex-direction: row
    align-items: center
    .link__back
      &:not(:last-child)
        margin-right: rem(12)
</style>
