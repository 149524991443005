<template>
  <div class="result-page__sectors unprint">
    <div class="title">
      {{ $t('message.result.nav.title') }}
    </div>
    <div class="content__panes-info">
      <img src="@/assets/img/icons/info.svg" alt="i" />
      <div class="text">
        {{ $t('message.result.nav.info') }}
      </div>
    </div>
    <div class="result-page__sectors-nav">
      <router-link
        v-for="(sector, i) in allSectors"
        class="link"
        :to="`/${$i18n.locale}/details/${i}`"
        :key="`sector-${i}`"
      >
        <div class="link__icon">
          <img src="@/assets/img/nav/slide-custom--color.svg" alt="" v-if="sector.isCustom" />
          <img src="@/assets/img/nav/slide-tn--color.svg" alt="" v-else />
        </div>
        <div class="link__content">
          <div class="link__text">
            {{ sector.name }}
          </div>
          <div class="link__footer">
            <div class="link__footer-item">
              <img src="@/assets/img/nav/slide-square.svg" class="img" alt="" />
              {{ format(sector.square) }} {{ $t('message.units.m2') }}
            </div>
            <div class="link__footer-item">
              <img src="@/assets/img/nav/slide-junctions.svg" class="img" alt="" />
              {{ sum(sector.junctions) }}
            </div>
          </div>
        </div>
      </router-link>
      <button class="add" @click="addNewSector">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="12" fill="#2ECC71"></circle>
          <path
            d="M12 18V6M18 12H6"
            stroke="white"
            stroke-width="2"
            stroke-miterlimit="22.9256"
            stroke-linejoin="round"
          ></path>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { formatBigNumber } from '@/utils/customFunctions'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  inject: ['slideToLast'],
  methods: {
    ...mapMutations({
      ADD_NEW_SECTOR: 'ADD_NEW_SECTOR'
    }),
    ...mapActions({
      markCalculationAsSaved: 'markCalculationAsSaved',
      resetUserInfoResults: 'resetUserInfoResults'
    }),
    format(n) {
      return formatBigNumber(n)
    },
    sum(arr) {
      return arr?.length
    },
    addNewSector() {
      this.ADD_NEW_SECTOR()
      if (this.isSavedCalculation) {
        this.resetUserInfoResults()
        this.markCalculationAsSaved(null)
      }
      this.$router.push(`/${this.$i18n.locale}/systems/${this.allSectors.length - 1}`)
    }
  },
  computed: {
    ...mapState({
      allSectors: state => state.sectors,
      isSavedCalculation: state => state.user.objectId
    })
  }
}
</script>

<style scoped lang="sass">
.result-page
  &__sectors
    +media((padding-top: (320: rem(32), 960: rem(51))))
    +media((width: (320: calc(100% - 32px), 650: 92%, 960: 100%)))
    margin: 0 auto
    .title
      @extend .fs-24
      +media((font-size: (320: rem(18), 960: rem(24))))
      +media((padding-bottom: (320: rem(12), 960: rem(20))))
      font-weight: bold
    .content__panes-info
      padding: 0 0 rem(18)
    &-nav
      display: grid
      +media((grid-template-columns: (320: 1fr, 480: repeat(2, 1fr), 576: repeat(3, 1fr), 650: repeat(4, 1fr), 800: repeat(5, 1fr), 960: repeat(6, 1fr))))
      .link
        background: #FFFFFF
        cursor: pointer
        transition: .5s
        text-decoration: none
        display: flex
        +media((flex-direction: (320: row, 576: column)))
        align-items: center
        justify-content: flex-start
        +media((padding: (320: rem(16) rem(16) rem(16) rem(8), 576: rem(16), 960: rem(29) rem(12) rem(9))))
        border: rem(1) solid #ECECEC
        box-sizing: border-box
        &__icon
          +media((width: (320: rem(72), 960: auto)))
          display: flex
          flex-direction: row
          align-items: center
          justify-content: center
          +media((margin-right: (320: rem(16), 576: 0)))
        &__content
          width: 100%
          +media((width: (320: auto, 576: 100%)))
        &__text
          +media((padding: (320: 0 0 rem(4), 576: rem(17) 0 rem(8))))
          @extend .fs-12
          text-align: left
          font-weight: bold
          color: $black
          width: 100%
        &__footer
          margin-top: auto
          display: flex
          align-items: center
          justify-content: space-between
          flex-wrap: wrap
          width: 100%
          @extend .fs-12
          color: $dark-grey
          flex-direction: row
          &-item
            display: flex
            flex-direction: row
            align-items: center
            &:first-child
              +media((margin-right: (320: rem(30), 576: 0)))
            .img
              margin-right: rem(6)
      .add
        @extend .clear-btn
        background: rgba(255, 255, 255, 0.5)
        border: rem(1) dashed #D6D6D6
        box-sizing: border-box
        border-radius: rem(4)
        +media((min-height: (320: rem(56), 576: rem(136))))
        +media((margin: (320: 0, 576: rem(8))))
        svg
          path, circle
            transition: .5s
        &:hover
          background: $green
          svg
            path
              stroke: $green
            circle
              fill: #fff
</style>
